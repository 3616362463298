import store from "@/store";
import config from "@/config";

export default {
    request: [
        (req) => {
            if (req.method === "get" && !IsUrlBlacklisted(req.url)) {
                store.commit("loader/setLoading", { [req.url]: true });
            }
            return req;
        }
    ],
    response: [
        (res) => {
            if (!IsUrlBlacklisted(res.config.url)) {
                store.commit("loader/setLoading", { [res.config.url]: false });
            }
            return res;
        }
    ]
};

const IsUrlBlacklisted = (url) =>
    config.loaders.blacklist.includes(url);
